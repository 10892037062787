const workProjects = [
  {
    src: "./images/workshop/workshop-gif.gif",
    alt: "workshop puzzle/escape games",
    labels: ["escape", "workshop", "learning", "120 p."],
    title: "work.projects.workshopEscape.title",
    content: "work.projects.workshopEscape.content",
    key: 5,
    page: "escape",
    byUs: true,
    partners: "TU-Delft",
  },
  // {
  //   src: "./images/game/escapebox-buurtparticipatie.jpeg",
  //   alt: "escapebox buurtparticipatie",
  //   title: "work.projects.buurtParticipatie.title",
  //   content: "work.projects.buurtParticipatie.content",
  //   labels: ["escape", "box", "serious game", "5 p."],
  //   key: 0,
  //   byUs: false,
  //   partners: "Hoogheemraadschap van Rijnland, Raccoon Serious Games",
  // },
  {
    src: "./images/game/escapebox-kinderdagopvang.jpg",
    alt: "escapebox-kinderdagopvang",
    title: "work.projects.kinderOpvang.title",
    content: "work.projects.kinderOpvang.content",
    labels: ["escape", "box", "serious game", "5 p."],
    key: 1,
    byUs: false,
    partners: "ExpInc",
  },
  {
    src: "./images/game/red-carpet.gif",
    alt: "escape room it's not your vault",
    title: "work.projects.redCarpet.title",
    content: "work.projects.redCarpet.content",
    labels: ["escape", "room", "entertainment", "20 p."],
    key: 2,
    byUs: false,
    partners: "Bioscopen Leiden, Raccoon Serious Games",
  },
  // {
  //   src: "./images/game/escapebox-ggd.png",
  //   alt: "Escape box GGD, Sexueele gezondeheid",
  //   title: "work.projects.GGD.title",
  //   content: "work.projects.GGD.content",
  //   labels: ["escape", "box", "serious game", "5 p."],
  //   key: 3,
  //   byUs: false,
  //   partners: "GGD, Raccoon Serious Games",
  // },
  {
    src: "./images/game/torenhoven.gif",
    alt: "Toren hoven",
    title: "work.projects.torenHoven.title",
    content: "work.projects.torenHoven.content",
    labels: ["escape", "room", "entertainment", "20 p."],
    key: 4,
    byUs: false,
    partners: "Raccoon Serious Games",
  },
];

// Seriuous game uni - HydroHero  ; jan 2019
// Sollicitatie box
// Yomp

export default workProjects;
