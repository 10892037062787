import { HoverRowMail, HoverRowPhone } from "../custom/HoverRowTypes";
import { useTranslation } from "react-i18next";

const ContactBanner = () => {
  const { t } = useTranslation();
  return (
    <div className={"my-20 w-4/5"}>
      <div className={"border-b-2 border-gray-300  h-1"} />
      <div className={"font-header pt-5 text-center text-xl"}>
        {t("contactBanner")}
      </div>
      <div className={"flex flex-row m-5 justify-center pl-4"}>
        <HoverRowMail email={"info@denkendendoeners.nl"} showText={false} />
        <HoverRowPhone number={"0641152536"} showText={false} />
      </div>
    </div>
  );
};

export default ContactBanner;
