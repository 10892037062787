import React, { useState, useEffect } from "react";
import i18n from "i18next";
import classNames from "classnames";
import useLanguage from "../hooks/useLanguage";

const ChangeLanguage = () => {
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const { languageOptions } = useLanguage();

  useEffect(() => {
    i18n.changeLanguage(activeLanguage);
  }, [activeLanguage]);

  return (
    <div
      className={"flex flex-col px-5"}
      onClick={() => {
        setActiveLanguage(
          activeLanguage === languageOptions.en
            ? languageOptions.nl
            : languageOptions.en
        );
        window.location.reload();
      }}
    >
      <button
        className={classNames(
          "border-b-2 text-xs font-header",
          activeLanguage === languageOptions.nl ? "font-bold" : ""
        )}
      >
        NL
      </button>
      <button
        className={classNames(
          "text-xs font-header",
          activeLanguage === languageOptions.en ? "font-bold" : ""
        )}
      >
        EN
      </button>
    </div>
  );
};

export default ChangeLanguage;
