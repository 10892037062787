import { useNavigate } from "react-router-dom";
import ChangeLanguage from "./ChangeLanguage";
import ThemeSelector from "./ThemeSelector";
import { useScrollPosition } from "../hooks/useScrollPosition";
import Logo from "./Logo";
import { useRef, useState } from "react";
import Menu from "./Menu";
import { isMobile } from "react-device-detect";

const Header = ({ setTheme, headerRef }) => {
  // Element scroll position
  const navigate = useNavigate();

  const leftSide = (
    <div className={"flex w-44 h-max"}>
      <img
        style={{ height: isMobile ? 38 : 40 }}
        src="./images/logo/logo-final.png"
        className=" cursor-pointer"
        alt="dedenkendedoeners_logo"
        onClick={() => navigate("/")}
      />
    </div>
  );

  const rightSide = (
    <div className={"flex flex-row w-44 items-center justify-end"}>
      <ChangeLanguage />
      <Menu />
      {/*<Burger headerRef={headerRef} />*/}
    </div>
  );
  return (
    <div className="flex fixed top-0 bg-white dark:bg-gray-800 dark:text-gray-100 z-20 w-full items-center justify-center pt-2 ">
      <div className="flex flex-row items-center justify-between w-4/5 border-b-2 border-gray-600 pb-2">
        {leftSide}
        {/*<Logo />*/}
        {rightSide}
      </div>
    </div>
  );
};

export default Header;
