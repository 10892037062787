import Section, { SectionMediaAlignment } from "../components/Section";

import BasePage from "../components/BasePage";
import ContentBlock from "../components/ContentBlock";
import { useRef } from "react";
import ContactBanner from "../components/ContactBanner";

function WorkshopEscapePage() {
  const aboutUsRef = useRef();
  const contactRef = useRef();
  const exampleRef = useRef();
  return (
    <BasePage
      content={
        <div className="flex flex-col justify-center items-center">
          <div className={"z-10 pt-6 pb-10 text-center max-w-2xl"}>
            <div className={"font-header text-2xl "}>
              Build your own puzzle game
            </div>
            <div className={"italic font-body text-purple-md text-lg"}>
              escape workshop: play, learn, explore
            </div>
            <div className={"font-body text-base pt-4  "}>
              De workshop Escape Rooms gaat in op het bevorderen van algemene
              design skills binnen je team. De methodes die je gebruikt om
              escape rooms te ontwikkelen vertalen zich bijna één-op-één met
              alledaagse ontwerp projecten. Studenten van de TU Delft leren hoe
              ze die methodes zich eigen kunnen maken.
            </div>
          </div>
          <img
            src="./images/workshop/workshop-gif.gif"
            className=""
            alt="escape workshop"
            style={{ width: 1000 }}
          />
          <ContactBanner />
        </div>
      }
    />
  );
}

export default WorkshopEscapePage;
