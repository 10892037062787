import classNames from "classnames";
import "./LocationClicker.css";
import loadImage from "../utils";
import useWindowDimensions from "../hooks/useWindowDimensions";
import OverlayClickImage from "./OverlayClickImage";
import useIsSmall from "../hooks/useIsSmall";

const LocationClicker = ({ width, ratio, backGround, clickInfo }) => {
  let { width: screenWidth } = useWindowDimensions();
  const isSmall = useIsSmall();
  const blockWidth = isSmall
    ? isSmall
      ? 1 * screenWidth // vertical
      : screenWidth * 1 // horizontal
    : screenWidth < width
    ? screenWidth * 0.9
    : width;
  const style = {
    width: blockWidth,
    height: blockWidth * ratio,
    backgroundImage: loadImage(backGround),
  };

  return (
    <div
      style={{ width: screenWidth, height: blockWidth * ratio }}
      className={"flex overflow-hidden justify-center"}
    >
      <div
        className={classNames(
          "LocationContainer BackgroundImage bg-contain"
          // isVertical ? "" : "bg-contain"
        )}
        style={style}
      >
        {clickInfo.map((vars, index) => (
          <OverlayClickImage
            key={"-" + index}
            blockWidth={blockWidth}
            index={index}
            {...vars}
          />
        ))}
      </div>
    </div>
  );
};

export default LocationClicker;
