import "./homePage.css";
import BasePage from "../components/BasePage";
import Partner from "../components/Partner";
import classNames from "classnames";
import useIsSmall from "../hooks/useIsSmall";
import ClickImageHome from "../components/clickImages/ClickImageHome";
import ClickImageHomeMobile from "../components/clickImages/ClickImageHomeMobile";
import { useTranslation } from "react-i18next";

const HomePage = () => {
  const isSmall = useIsSmall();
  const { t } = useTranslation();

  const banner = (
    <div className={"relative"}>
      <div
        className={classNames(
          "absolute left-1/2 transform -translate-x-1/2 z-10 pointer-events-none font-bold font-header text-center ",
          isSmall ? "pt-20 min-w-max text-2xl" : "pt-24 text-3xl"
        )}
      >
        <div className={""}>
          {t("home.title.line1.black")}
          <span className={"text-purple-md"}>
            {t("home.title.line1.purple")}
          </span>
        </div>
        <div>
          {t("home.title.line2.black")}
          <span className={"text-purple-md"}>
            {" "}
            {t("home.title.line2.purple")}
          </span>
        </div>
      </div>
      {isSmall ? <ClickImageHomeMobile /> : <ClickImageHome />}
    </div>
  );

  return (
    <BasePage
      content={
        <div className="flex flex-col justify-center items-center">
          {banner}
          <div className={"flex flex-col my-20 justify-center items-center"}>
            <div
              className={classNames(
                "border-b-2 border-gray-300  ",
                isSmall ? "w-60" : " w-80"
              )}
            />
            <div className={"font-header text-2xl text-center pb-4 pt-4"}>
              {t("home.partners")}
            </div>
            <Partner
              image={"./images/partners/tudelft.png"}
              alt={"TU Delft"}
              url={"https://www.tudelft.nl/"}
            />
          </div>
        </div>
      }
    />
  );
};

export default HomePage;
