import Burger from "@animated-burgers/burger-squeeze";
import "@animated-burgers/burger-squeeze/dist/styles.css";
import { useEffect, useState } from "react";
import "./Menu.css";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Menu = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const links = (
    <div
      className={classNames(
        "absolute right-0 top-0 z-10 bg-yellow-light h-screen",
        "pt-24 font-logo font-light",
        isOpen ? "w-60 pl-5" : "w-0",
        "transition-width transition-slowest ease"
      )}
    >
      <div className={"menu-link"} onClick={() => navigate("/about")}>
        {t("menu.about")}
      </div>
      <div className={"menu-link"} onClick={() => navigate("/diensten")}>
        {t("menu.services")}
      </div>
      <div className={"menu-link menu-sub"} onClick={() => navigate("/games")}>
        {t("menu.games")}
      </div>
      <div
        className={"menu-link menu-sub"}
        onClick={() => navigate("/workshops")}
      >
        {t("menu.workshops")}
      </div>
      <div className={"menu-link"} onClick={() => navigate("/werk")}>
        {t("menu.work")}
      </div>
      <div className={"menu-link"} onClick={() => navigate("/contact")}>
        {t("menu.contact")}
      </div>
    </div>
  );

  return (
    <>
      <Burger isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
      {links}
    </>
  );
};

export default Menu;
