import i18n from "i18next";

export default function useLanguage() {
  const languageOptions = {
    nl: "nl",
    en: "en",
  };
  const language = i18n.language;
  const isEnglish = language === languageOptions.en;
  const isDutch = language === languageOptions.nl;

  return { language, isDutch, isEnglish, languageOptions };
}
