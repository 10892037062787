import { isMobile } from "react-device-detect";

const Partner = ({ name, image, alt, url }) => {
  return (
    <img
      // style={{ height: isMobile ? 30 : 40 }}
      src={image}
      className={"w-40"}
      alt={alt}
      onClick={() => window.open(url)}
    />
  );
};

export default Partner;
