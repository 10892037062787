import BasePage from "../components/BasePage";
import ContactBanner from "../components/ContactBanner";

function CreativeFacilitationPage() {
  return (
    <BasePage
      content={
        <div className="flex flex-col justify-center items-center">
          <div className={"z-10 pt-8 text-center max-w-2xl"}>
            <div className={"font-header text-2xl "}>
              Activeer jou creative process
            </div>
            <div className={"italic font-body text-purple-md text-lg"}>
              play, draw, design
            </div>
            <div className={"font-body text-base pt-4  "}>
              Wij helpen jou met het opzetten van een creative process om het
              maximale uit je team te halen en hun te begeleiden
            </div>
          </div>

          <ContactBanner />
        </div>
      }
    />
  );
}

export default CreativeFacilitationPage;
