import ContentBlock from "./ContentBlock";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { image } from "tailwindcss/lib/util/dataTypes";
import useWindowDimensions from "../hooks/useWindowDimensions";
import useIsSmall from "../hooks/useIsSmall";
export const SectionMediaAlignment = {
  left: 0,
  right: 0,
  above: 0,
};

function Section({
  children,
  alignment,
  childRef,
  className,
  smallFrom = 650,
}) {
  switch (alignment) {
    case SectionMediaAlignment.left:
      // const leftContent = media;
      // const rightContent = content;
      // const leftClassname
      break;
    default:
    // console.log("nothing");
  }
  const [finalChildren, setFinalChildren] = useState(children);
  const isSmall = useIsSmall(smallFrom);

  useEffect(() => {
    if (isSmall) {
      // Force the same order on small width screens
      if (alignment === SectionMediaAlignment.left) {
        const content = children.filter(
          (child) => child.type !== (<img />).type
        );
        const images = children.filter(
          (child) => child.type === (<img />).type
        );
        setFinalChildren([...content, ...images]);
      }
    } else {
      setFinalChildren(children);
    }
  }, [isSmall]);

  return (
    <div
      ref={childRef}
      className={classNames(
        "section flex flex-row my-5 w-full flex-wrap justify-center",
        className
      )}
    >
      {finalChildren}
    </div>
  );
}

export default Section;
