import Section, { SectionMediaAlignment } from "../components/Section";

import BasePage from "../components/BasePage";
import ContentBlock from "../components/ContentBlock";
import { useRef } from "react";
import ContactBanner from "../components/ContactBanner";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WorkshopPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <BasePage
      content={
        <div className="flex flex-col justify-center items-center">
          <div className={"z-10 pt-6 pb-10 text-center max-w-2xl"}>
            <div className={"font-header text-2xl "}>
              {t("workshops.title")}
            </div>
            <div className={"italic font-body text-purple-md text-lg"}>
              {t("workshops.subTitle")}
            </div>
            <div className={"font-body text-base pt-4"}>
              {t("workshops.description")}
              <span
                onClick={() => navigate("/werk")}
                className={"font-bold text-purple-md hover:cursor-pointer"}
              >
                {t("workshops.descriptionLink")}
              </span>
              .
            </div>
          </div>
          <img
            src="./images/workshop/workshop-gif.gif"
            className=""
            alt="escape workshop"
            style={{ width: 800 }}
          />
          <Section>
            <ContentBlock
              bgImage={"./images/blobs/blobs-01.png"}
              title={t("workshops.overview.title")}
              content={t("workshops.overview.description")}
              className={""}
            />
            <img
              src={"./images/workshop/workshops-overzicht1.png"}
              className="block-image "
              alt="workshop overzicht"
              style={{ width: 200 }}
            />
          </Section>
          <Section alignment={SectionMediaAlignment.left}>
            <img
              src={"./images/workshop/workshops-userexperience1.png"}
              className="block-image "
              alt="workshop user experience"
              style={{ width: 200 }}
            />
            <ContentBlock
              bgImage={"./images/blobs/blobs-04.png"}
              title={t("workshops.userExperience.title")}
              content={t("workshops.userExperience.description")}
              className={""}
            />
          </Section>
          <Section alignment={SectionMediaAlignment.left}>
            <ContentBlock
              bgImage={"./images/blobs/blobs-02.png"}
              title={t("workshops.communication.title")}
              content={t("workshops.communication.description")}
              className={""}
            />
            <img
              src={"./images/workshop/workshop-communication.png"}
              className="block-image "
              alt="workshop communicatie"
              style={{ width: 200 }}
            />
          </Section>
          <Section alignment={SectionMediaAlignment.left}>
            <img
              src={"./images/workshop/workshop-techniek1.png"}
              className="block-image "
              alt="workshop techniek"
              style={{ width: 200 }}
            />{" "}
            <ContentBlock
              bgImage={"./images/blobs/blobs-05.png"}
              title={t("workshops.technical.title")}
              content={t("workshops.technical.description")}
              className={""}
            />
          </Section>
          <Section alignment={SectionMediaAlignment.left}>
            <ContentBlock
              bgImage={"./images/blobs/blobs-03.png"}
              title={t("workshops.testing.title")}
              content={t("workshops.testing.description")}
              className={""}
            />
            <img
              src={"./images/workshop/workshops-testen1.png"}
              className="block-image "
              alt="workshop testen"
              style={{ width: 200 }}
            />{" "}
          </Section>
          <ContactBanner />
        </div>
      }
    />
  );
};

export default WorkshopPage;
