import classNames from "classnames";
import { useState, useEffect } from "react";
import { click } from "@testing-library/user-event/dist/click";
import useBlockHover from "../hooks/useBlockHover";

const Tag = ({
  text,
  onClick = null,
  filled = false,
  disabled = false,
  color = "purple-md",
  className,
}) => {
  const isClickable = onClick !== null;
  const [clicked, setClicked] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const blockHover = useBlockHover(clicked, isHover);

  const [primaryText, primaryBg, primaryBorder] = [
    "text-",
    "bg-",
    "border-",
  ].map((item) => item + color);

  const filledStyle = classNames("bg-warmGray-50", primaryText, primaryBorder);
  const emptyStyle = classNames("text-warmGray-100", primaryBg, primaryBorder);
  const hoverFullStyle = classNames(
    "hover:" + primaryBg,
    "hover:text-warmGray-100 hover:cursor-pointer ",
    "hover:" + primaryBorder
  );
  const hoverEmptyStyle = classNames(
    "hover:bg-warmGray-50 hover:cursor-pointer",
    "hover:" + primaryText,
    "hover:" + primaryBorder
  );

  const disabledStyle = "bg-warmGray-200 text-warmGray-400 border-warmGray-200";
  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={classNames(
        "mr-1 my-1 px-2 h-5 flex items-center justify-center",
        "border-2 rounded-2xl",
        "font-header text-xs text-center  ",
        "transition-color transition-slowest ease duration-300",
        disabled
          ? disabledStyle
          : clicked
          ? filled
            ? emptyStyle
            : filledStyle
          : filled
          ? filledStyle
          : emptyStyle,
        !blockHover && isClickable
          ? disabled
            ? filled
              ? hoverFullStyle
              : hoverEmptyStyle
            : clicked
            ? filled
              ? hoverEmptyStyle
              : hoverFullStyle
            : filled
            ? hoverFullStyle
            : hoverEmptyStyle
          : "",
        className
      )}
      onClick={() => {
        onClick();
        setClicked(!clicked);
      }}
    >
      {text}
      <div
        className={classNames(
          "hidden",
          "text-yellow-dark bg-yellow-dark border-yellow-dark",
          "text-yellow-light bg-yellow-light border-yellow-light",
          "text-green-dark bg-green-dark border-green-dark",
          "text-green-light bg-green-light border-green-light",
          "text-purple-md bg-purple-md border-purple-md",
          "text-warmGray-400 bg-warmGray-400 border-warmGray-400",
          "hover:text-yellow-dark hover:bg-yellow-dark hover:border-yellow-dark ",
          "hover:text-yellow-light hover:bg-yellow-light hover:border-yellow-light ",
          "hover:text-green-dark hover:bg-green-dark hover:border-green-dark ",
          "hover:text-green-light hover:bg-green-light hover:border-green-light ",
          "hover:text-purple-md hover:bg-purple-md hover:border-purple-m d"
        )}
      />
    </div>
  );
};
export default Tag;
