import Section, { SectionMediaAlignment } from "../components/Section";
import { useTranslation } from "react-i18next";

import "./homePage.css";
import BasePage from "../components/BasePage";
import ContentBlock from "../components/ContentBlock";
import ContactBanner from "../components/ContactBanner";
import useIsSmall from "../hooks/useIsSmall";
import classNames from "classnames";
import ClickImageVision from "../components/clickImages/ClickImageVision";

const AboutPage = () => {
  const { t } = useTranslation();
  const isSmall = useIsSmall(1100);
  //Smallaboutcontent is when on mobile the tekst in the images is not visible
  const smallAboutContent = (
    <div className={"p-6"}>
      <Section alignment={SectionMediaAlignment.left}>
        <img
          src={"./images/vision/mobile/visie-mobile-castle.png"}
          className="block-image"
          alt="logo"
          style={{ width: 1000 }}
        />
        <ContentBlock
          title={t("about.thinking.title")}
          content={t("about.thinking.description")}
          className={""}
        />
      </Section>
      <Section alignment={SectionMediaAlignment.left}>
        <img
          src={"./images/vision/mobile/visie-mobile-ruin.png"}
          className="block-image"
          alt="logo"
          style={{ width: 1000 }}
        />
        <ContentBlock
          title={t("about.doing.title")}
          content={t("about.doing.description")}
          className={""}
        />
      </Section>
      <Section alignment={SectionMediaAlignment.left}>
        <img
          src={"./images/vision/mobile/visie-mobile-house.png"}
          className="block-image"
          alt="logo"
          style={{ width: 1000 }}
        />
        <ContentBlock
          title={t("about.together.title")}
          content={t("about.together.description")}
          className={""}
        />
      </Section>
    </div>
  );

  // Warning"  width van de tekst aanpassen is lastig omdat de width van de pagina weggeduwt wordt door de image.
  return (
    <BasePage
      content={
        <div className="flex flex-col justify-center items-center max-w-2xl">
          <div
            className={classNames(
              "z-10 text-center px-14 ",
              isSmall ? "pt-6" : "pt-6 "
            )}
          >
            <div className={"font-header text-2xl"}>{t("about.title")} </div>
            <div className={"font-body text-base"}>
              {t("about.description")}
            </div>
          </div>
          <ClickImageVision />
          {isSmall && smallAboutContent}
          <ContactBanner />
        </div>
      }
    />
  );
};

export default AboutPage;
