import BasePage from "../components/BasePage";
import ContactBanner from "../components/ContactBanner";
import Section, { SectionMediaAlignment } from "../components/Section";
import ContentBlock from "../components/ContentBlock";
import useIsSmall from "../hooks/useIsSmall";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function GamesPage() {
  const navigate = useNavigate();

  const { t } = useTranslation();
  return (
    <BasePage
      content={
        <div className="flex flex-col justify-center items-center">
          <div className={"z-10 py-2 text-center max-w-2xl"}>
            <div className={"font-header text-2xl "}>{t("games.title")}</div>
            <div className={"italic font-body text-purple-md text-lg"}>
              {t("games.subTitle")}
            </div>
            <div className={"font-body text-base pt-4  "}>
              {t("games.description")}
              <span
                onClick={() => navigate("/werk")}
                className={"font-bold text-purple-md hover:cursor-pointer"}
              >
                {t("games.descriptionLink")}
              </span>
              !
            </div>
          </div>
          <img
            src="./images/game/game-gif.gif"
            className="my-2 object-center object-cover pt-4"
            alt="game examples"
            style={{ width: 800, height: 400 }}
          />
          <Section>
            <ContentBlock
              bgImage={"./images/blobs/blobs-01.png"}
              title={t("games.story.title")}
              content={t("games.story.description")}
              className={""}
            />
            <img
              src={"./images/game/games-verhaal1.png"}
              className="block-image "
              alt="logo"
              style={{ width: 200 }}
            />
          </Section>
          <Section alignment={SectionMediaAlignment.left}>
            <img
              src={"./images/game/games-speels1.png"}
              className="block-image"
              alt="logo"
              style={{ width: 200 }}
            />
            <ContentBlock
              bgImage={"./images/blobs/blobs-04.png"}
              title={t("games.play.title")}
              content={t("games.play.description")}
              className={""}
            />
          </Section>
          <Section alignment={SectionMediaAlignment.left}>
            <ContentBlock
              bgImage={"./images/blobs/blobs-02.png"}
              title={t("games.iterative.title")}
              content={t("games.iterative.description")}
              className={""}
            />
            <img
              src={"./images/game/games-itereren.png"}
              className="block-image"
              alt="logo"
              style={{ width: 200 }}
            />
          </Section>
          <ContactBanner />
        </div>
      }
    />
  );
}

export default GamesPage;
