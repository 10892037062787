import classNames from "classnames";
import { useState } from "react";

const HoverRow = ({ imageBasic, imageHover, text, onClick }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setIsActive(true);
      }}
      onMouseLeave={() => setIsActive(false)}
      className={classNames(
        "items-center hover:cursor-pointer hover:text-purple-md",
        "transition-color  transition-slowest ease duration-300"
      )}
      onClick={onClick}
    >
      <img
        style={{ width: 50 }}
        src={isActive ? imageHover : imageBasic}
        className=" cursor-pointer"
        alt="mail_icon"
      />
      <div className="pl-2">{text}</div>
    </div>
  );
};

export default HoverRow;
