import Section, { SectionMediaAlignment } from "../components/Section";

import BasePage from "../components/BasePage";
import { useNavigate } from "react-router-dom";
import ContentBlock from "../components/ContentBlock";
import { useRef } from "react";
import ContactBanner from "../components/ContactBanner";
import ClickImageServicesSmall from "../components/clickImages/ClickImageServicesSmall";
import { useTranslation } from "react-i18next";

const ServicesPage = () => {
  const aboutUsRef = useRef();
  const exampleRef = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <BasePage
      content={
        <div className="flex flex-col  justify-center items-center w-full">
          <div className={"z-10 mt-2 mb-8 font-header text-2xl text-center"}>
            <div className={""}> {t("services.title")} </div>
            <div className={"font-body text-base max-w-2xl "}>
              {t("services.description1")}

              <span
                onClick={() => navigate("/werk")}
                className={"font-bold text-purple-md hover:cursor-pointer"}
              >
                {"  "} {t("services.descriptionLink")}
              </span>
              {t("services.description2")}
            </div>
          </div>
          <ClickImageServicesSmall />

          <Section childRef={aboutUsRef}>
            <ContentBlock
              bgImage={"./images/blobs/blobs-01.png"}
              title={t("services.creativity.title")}
              content={t("services.creativity.description")}
              className={""}
            />
            <img
              src="./images/utils/creativity-01small.png"
              className="block-image "
              alt="logo"
              style={{ width: 200 }}
            />
          </Section>
          <Section alignment={SectionMediaAlignment.left} childRef={exampleRef}>
            <img
              src="./images/utils/proces-small.png"
              className="block-image"
              alt="logo"
              style={{ width: 200 }}
            />
            <ContentBlock
              bgImage={"./images/blobs/blobs-04.png"}
              title={t("services.process.title")}
              content={t("services.process.description")}
              className={""}
            />
          </Section>
          <Section alignment={SectionMediaAlignment.left} childRef={exampleRef}>
            <ContentBlock
              bgImage={"./images/blobs/blobs-02.png"}
              title={t("services.teamwork.title")}
              content={t("services.teamwork.description")}
              className={""}
            />
            <img
              src="./images/utils/teamwork-01small.png"
              className="block-image"
              alt="logo"
              style={{ width: 200 }}
            />
          </Section>
          <ContactBanner />
        </div>
      }
    />
  );
};

export default ServicesPage;
