import Section, { SectionMediaAlignment } from "../components/Section";

import BasePage from "../components/BasePage";
import { useNavigate } from "react-router-dom";
import ContentBlock from "../components/ContentBlock";
import useIsSmall from "../hooks/useIsSmall";
import workProjects from "../constants/workProjects";
import Tag from "../components/Tag";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const WorkBox = ({
  src,
  alt,
  title,
  content,
  labels,
  isSmall,
  key,
  byUs,
  tagText,
  partners,
  onClick,
}) => {
  return (
    <Section alignment={SectionMediaAlignment.left} key={key} smallFrom={750}>
      <div className={"relative"}>
        <Tag
          text={tagText}
          className={"absolute top-5 left-2 "}
          color={byUs ? "green-dark" : "warmGray-400"}
          filled={false}
        />
        <img
          src={src}
          className="block-image"
          alt={alt}
          style={{ width: isSmall ? 700 : 300 }}
        />
      </div>
      <ContentBlock
        title={title}
        content={content}
        className={""}
        labels={labels}
        smallFrom={750}
        onClick={onClick}
        subText={partners}
      />
    </Section>
  );
};

const ServicesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const uniqueTags = workProjects
    .map((project) => project.labels)
    .flat()
    .filter((v, i, a) => a.indexOf(v) === i);

  const isSmall = useIsSmall(750);
  const [activeTags, setActiveTags] = useState([]);
  const [activeProjects, setActiveProjects] = useState([]);

  const updateTagFilters = (tag) => {
    activeTags.includes(tag)
      ? setActiveTags([...activeTags].filter((item) => item !== tag))
      : setActiveTags([...activeTags, tag]);
  };

  useEffect(() => {
    setActiveProjects(
      workProjects.filter(
        (project, index) =>
          activeTags.filter((tag) => !project.labels.includes(tag)).length === 0
      )
    );
  }, [activeTags]);

  return (
    <BasePage
      content={
        <div className="flex flex-col  justify-center items-center w-full">
          <div className={"z-10 pt-2 font-header text-2xl text-center mb-4 "}>
            <div className={""}>{t("work.title")}</div>
            <div className={"font-body text-base max-w-2xl "}>
              {t("work.description")}
            </div>
          </div>
          <div className={"flex flex-row flex-wrap"}>
            {uniqueTags.map((label) => (
              <Tag
                key={label}
                text={label}
                onClick={() => updateTagFilters(label)}
                filled
                disabled={!activeTags.includes(label)}
              />
            ))}
          </div>
          {activeProjects.map((box) =>
            WorkBox({
              ...box,
              title: t(box.title),
              content: t(box.content),
              tagText: box.byUs ? t("work.byUs") : t("work.collaboration"),
              isSmall,
              onClick: box.page ? () => navigate(box.page) : null,
            })
          )}
        </div>
      }
    />
  );
};

export default ServicesPage;
