import LocationClicker from "../LocationClicker";
import useLanguage from "../../hooks/useLanguage";

const ClickImageVision = () => {
  const { isDutch } = useLanguage();

  return (
    <LocationClicker
      width={1250}
      ratio={0.57}
      clickInfo={[
        {
          onClick: () => {},
          colorImage: isDutch
            ? "./images/vision/visie-kasteel1.png"
            : "./images/vision/visie-kasteel-en.png",
          containerWidth: 700,
          blocks: [
            {
              x1: 0,
              x2: 240,
              y1: 50,
              y2: 380,
              z: 3,
              bg: "bg-emerald-400",
            },

            {
              x1: 60,
              x2: 220,
              y1: 240,
              y2: 300,
              z: 5,
              bg: "bg-green-dark",
            },
          ],
        },
        {
          onClick: () => {},
          colorImage: isDutch
            ? "./images/vision/visie-huis1.png"
            : "./images/vision/visie-huis-en.png",
          containerWidth: 700,
          blocks: [
            {
              x1: 240,
              x2: 460,
              y1: 50,
              y2: 380,
              z: 3,
              bg: "bg-amber-300 ",
            },
            {
              x1: 75,
              x2: 230,
              y1: 50,
              y2: 110,
              z: 5,
              bg: "bg-red-200",
            },
          ],
        },
        {
          onClick: () => {},
          colorImage: isDutch
            ? "./images/vision/visie-bouwval1.png"
            : "./images/vision/visie-bouwval-en.png",
          containerWidth: 700,
          blocks: [
            {
              x1: 470,
              x2: 610,
              y1: 60,
              y2: 120,
              z: 6,
              bg: "bg-blue-200",
            },
            {
              x1: 460,
              x2: 700,
              y1: 50,
              y2: 380,
              z: 3,
              bg: "bg-cyan-200",
            },
          ],
        },
      ]}
      backGround={"./images/vision/visie-basic-small.png"}
    />
  );
};

export default ClickImageVision;
