import LocationClicker from "../LocationClicker";
import { useNavigate } from "react-router-dom";
import useIsSmall from "../../hooks/useIsSmall";

const ClickImageServicesSmall = () => {
  const navigate = useNavigate();
  const isSmall = useIsSmall();

  return (
    <LocationClicker
      width={1000}
      ratio={0.6}
      clickInfo={[
        {
          onClick: () => navigate("/games"),
          colorImage: "./images/services/Diensten-games.png",
          // colorImage: "./images/services/Diensten-games-small.png",
          containerWidth: 700,
          blocks: [
            {
              x1: 80,
              x2: 280,
              y1: 10,
              y2: 400,
              z: 6,
              bg: "bg-cyan-200",
            },
          ],
        },
        {
          onClick: () => navigate("/workshops"),
          colorImage: "./images/services/Diensten-workshops.png",
          containerWidth: 700,
          blocks: [
            {
              x1: 270,
              x2: 650,
              y1: 10,
              y2: 400,
              z: 5,
              bg: "bg-red-200",
            },
            {
              x1: 245,
              x2: 280,
              y1: 210,
              y2: 245,
              z: 7,
              bg: "bg-red-200",
            },
          ],
        },
      ]}
      backGround={
        isSmall
          ? "./images/services/diensten-mobile.png"
          : "./images/services/Diensten.png"
      }
    />
  );
};

export default ClickImageServicesSmall;
