import React, { useRef, useState } from "react";
import Header from "./Header";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

const BasePage = ({ content }) => {
  const [theme, setTheme] = useState("light");
  const headerRef = useRef();
  let location = useLocation();
  console.log();
  return (
    <div className={classNames(theme, "w-100")}>
      <div
        className={classNames(
          "flex dark:bg-gray-800 flex-col justify-center items-center"
        )}
      >
        <div className="flex justify-center items-center  w-full flex-col mt-20">
          <Header setTheme={setTheme} headerRef={headerRef} />
          <div ref={headerRef} className="flex w-4/5 justify-center">
            {content}
          </div>
          <footer></footer>
        </div>
      </div>
    </div>
  );
};

export default BasePage;
