import { useState, useEffect } from "react";

export default function useBlockHover(clicked, isHover) {
  const [hoverBeforeClick, setHoverBeforeClick] = useState(false);
  const [blockHover, setBlockHover] = useState(false);

  useEffect(() => {
    if (isHover) {
      if (!clicked) {
        if (hoverBeforeClick) {
          setBlockHover(true);
          setHoverBeforeClick(false);
        } else {
          setHoverBeforeClick(true);
        }
      } else {
        if (hoverBeforeClick) {
          setBlockHover(true);
          setHoverBeforeClick(false);
        } else {
          setHoverBeforeClick(true);
        }
      }
    } else {
      setHoverBeforeClick(false);
      setBlockHover(false);
    }
  }, [isHover, clicked]);
  return blockHover;
}
