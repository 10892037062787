import Section from "../components/Section";
import { useTranslation } from "react-i18next";

import "./homePage.css";
import BasePage from "../components/BasePage";
import { useRef } from "react";
import PersonDetails from "../components/PersonDetails";

const ContactPage = () => {
  const { t } = useTranslation();

  return (
    <BasePage
      content={
        <div className="flex flex-col items-center justify-center w-4/5">
          <div className={"z-10 pt-12 font-header text-2xl text-center "}>
            <div className={""}>{t("contact.title.part1")}</div>
            <div>
              {t("contact.title.part2")}{" "}
              <span className={"text-purple-md"}>
                {t("contact.title.link")}
              </span>{" "}
              {t("contact.title.part3")}
            </div>
          </div>
          <Section className="justify-evenly max-w-2xl">
            <PersonDetails
              bgImage="./images/contact/personas-02.svg"
              name="Tobias"
              email="tobias@denkendedoeners.nl"
              number="06 48166934"
              linkedInUrl="https://www.linkedin.com/in/tobias-hebbink/"
            />
            <PersonDetails
              bgImage="./images/contact/personas-01.svg"
              name="Daniel"
              email="daniel@denkendedoeners.nl"
              number="06 41152536"
              linkedInUrl="https://www.linkedin.com/in/swaab/"
            />
          </Section>
        </div>
      }
    />
  );
};

export default ContactPage;
