import LocationClicker from "../LocationClicker";
import { useNavigate } from "react-router-dom";
import useLanguage from "../../hooks/useLanguage";

const ClickImageHome = () => {
  const navigate = useNavigate();
  const { isDutch } = useLanguage();
  return (
    <LocationClicker
      width={1250}
      ratio={0.57}
      clickInfo={[
        {
          onClick: () => navigate("/diensten"),
          colorImage: isDutch
            ? "./images/crossroad/Crossroads-diensten-small.png"
            : "./images/crossroad/crossroads-services-en.png",
          containerWidth: 700,
          blocks: [
            {
              x1: 190,
              x2: 265,
              y1: 155,
              y2: 195,
              z: 6,
              bg: "bg-blue-200",
            },
            {
              x1: 80,
              x2: 265,
              y1: 180,
              y2: 390,
              z: 3,
              bg: "bg-cyan-200",
            },
          ],
        },
        {
          onClick: () => navigate("/about"),
          colorImage: isDutch
            ? "./images/crossroad/Crossroads-overons-small.png"
            : "./images/crossroad/crossroads-vision-en.png",
          containerWidth: 700,
          blocks: [
            {
              x1: 265,
              x2: 410,
              y1: 180,
              y2: 390,
              z: 3,
              bg: "bg-amber-300 ",
            },
            {
              x1: 220,
              x2: 295,
              y1: 175,
              y2: 225,
              z: 5,
              bg: "bg-red-200",
            },
          ],
        },
        {
          onClick: () => navigate("/contact"),
          colorImage: isDutch
            ? "./images/crossroad/Crossroads-contact-small.png"
            : "./images/crossroad/crossroads-contact-en.png",
          containerWidth: 700,
          blocks: [
            {
              x1: 410,
              x2: 650,
              y1: 180,
              y2: 390,
              z: 3,
              bg: "bg-emerald-400",
            },

            {
              x1: 210,
              x2: 305,
              y1: 220,
              y2: 250,
              z: 5,
              bg: "bg-green-dark",
            },
          ],
        },
        {
          onClick: () => navigate("/werk"),
          colorImage: isDutch
            ? "./images/crossroad/Crossroads-werk-small.png"
            : "./images/crossroad/crossroads-work-en.png",
          containerWidth: 700,
          blocks: [
            {
              x1: 530,
              x2: 650,
              y1: 175,
              y2: 270,
              z: 7,
              bg: "bg-gray-400",
            },
          ],
        },
      ]}
      backGround={
        isDutch
          ? "./images/crossroad/Crossroads-basic-small.png"
          : "./images/crossroad/crossroads-basic-en.png"
      }
    />
  );
};

export default ClickImageHome;
