import loadImage from "../utils";
import classNames from "classnames";
import useIsSmall from "../hooks/useIsSmall";
import Tag from "./Tag";

const ContentBlock = ({
  bgImage,
  title,
  content,
  className,
  onClick,
  labels = [],
  smallFrom = 650,
  subText,
}) => {
  const isSmall = useIsSmall(smallFrom);

  return (
    <div
      className={classNames(
        "flex flex-col bg-center  bg-no-repeat p-5 bg-opacity-75  max-w-prose font-body ",
        isSmall ? "bg-cover flex-grow-1 " : "bg-contain flex-2",
        className,
        onClick ? "hover:cursor-pointer" : ""
      )}
      style={{ backgroundImage: loadImage(bgImage) }}
      onClick={onClick}
    >
      <div className={"flex flex-wrap items-center"}>
        <div className="text-xl font-header pr-4 ">{title}</div>
        <div className={"flex flex-row flex-grow items-center flex-wrap"}>
          {labels.map((label) => (
            <Tag text={label} filled={true} />
          ))}
        </div>
      </div>
      <div>{content}</div>
      {subText !== "" && <div className={"italic text-sm"}>{subText}</div>}
    </div>
  );
};

export default ContentBlock;
