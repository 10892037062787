import LocationClicker from "../LocationClicker";
import { useNavigate } from "react-router-dom";
import useLanguage from "../../hooks/useLanguage";

const ClickImageHomeMobile = () => {
  const navigate = useNavigate();
  const { isDutch } = useLanguage();

  return (
    <LocationClicker
      width={1250}
      ratio={1.5}
      clickInfo={[
        {
          onClick: () => navigate("/diensten"),
          containerWidth: 700,
          blocks: [
            {
              x1: 10,
              x2: 245,
              y1: 355,
              y2: 425,
              z: 6,
              bg: "bg-blue-200",
            },
          ],
        },
        {
          onClick: () => navigate("/about"),
          containerWidth: 700,
          blocks: [
            {
              x1: 120,
              x2: 270,
              y1: 405,
              y2: 510,
              z: 3,
              bg: "bg-amber-300 ",
            },
          ],
        },
        {
          onClick: () => navigate("/contact"),
          containerWidth: 700,
          blocks: [
            {
              x1: 70,
              x2: 300,
              y1: 500,
              y2: 570,
              z: 3,
              bg: "bg-emerald-400",
            },
          ],
        },
        {
          onClick: () => navigate("/werk"),
          containerWidth: 700,
          blocks: [
            {
              x1: 420,
              x2: 650,
              y1: 420,
              y2: 650,
              z: 7,
              bg: "bg-gray-400",
            },
          ],
        },
      ]}
      backGround={
        isDutch
          ? "./images/crossroad/verticle_mobile.png"
          : "./images/crossroad/home-mobile-en.png"
      }
    />
  );
};

export default ClickImageHomeMobile;
