import { useState, useEffect } from "react";
import useWindowDimensions from "./useWindowDimensions";

export default function useIsSmall(maxWidth = 550) {
  const [isSmall, setIsSmall] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setIsSmall(width < maxWidth);
  }, [width]);

  return isSmall;
}
