import loadImage from "../utils";
import classNames from "classnames";

import { HoverRowMail, HoverRowPhone } from "../custom/HoverRowTypes";

const ContentBlock = ({
  bgImage,
  name,
  linkedInUrl,
  website,
  number,
  email,
  className,
}) => {
  return (
    <div className="flex flex-col  justify-center items-center m-8">
      <div
        className={classNames(
          "bg-center items-center bg-no-repeat p-2 h-52",
          className
        )}
        style={{ backgroundImage: loadImage(bgImage), width: 160 }}
      />
      <div
        className={classNames(
          "text-xl font-header hover:cursor-pointer hover:text-purple-md pb-2",
          "transition-color  transition-slowest ease duration-300"
        )}
        onClick={() => window.open(linkedInUrl)}
      >
        {name}
      </div>
      <div className={"flex flex-row items-center"}>
        <HoverRowMail email={email} showText={false} />
        <HoverRowPhone number={number} showText={false} />
      </div>
    </div>
  );
};

export default ContentBlock;
