import React from "react";

import HomePage from "./pages/HomePage";
import WorkshopEscapePage from "./pages/WorkshopEscapePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import ServicesPage from "./pages/ServicesPage";
import { HashRouter, Route, Routes } from "react-router-dom";
import GamesPage from "./pages/GamesPage";
import CreativeFacilitationPage from "./pages/CreativeFacilitationPage";
import WorkPage from "./pages/WorkPage";
import WorkshopPage from "./pages/WorkshopPage";
import { Helmet } from "react-helmet";

const MyRouter = () => {
  return DomRouter();
};

const DomRouter = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="diensten" element={<ServicesPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="werk/escape" element={<WorkshopEscapePage />} />
        <Route path="workshops" element={<WorkshopPage />} />
        <Route path="games" element={<GamesPage />} />
        <Route path="creative" element={<CreativeFacilitationPage />} />
        <Route path="werk" element={<WorkPage />} />
      </Routes>
    </HashRouter>
  );
};

export default MyRouter;
