import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { color } from "tailwindcss/lib/util/dataTypes";

export default function OverlayClickImage({
  colorImage,
  index,
  blockWidth,
  blocks,
  onClick,
  containerWidth,
}) {
  const [isActive, setIsActive] = useState(false);

  const overlay = () => {
    return colorImage ? (
      <img
        key={"overlay-" + index}
        src={colorImage}
        className={classNames(
          "z-4 absolute",
          isActive ? "opacity-100" : "opacity-0",
          "transition-opacity  transition-slowest ease duration-300"
        )}
        alt="overlay"
        style={{
          width: blockWidth,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: "auto",
        }}
      />
    ) : (
      <React.Fragment key={"overlay-empoty-" + index} />
    );
  };

  const clickElements = (blocks || []).map((info, clickIndex) => {
    let { x1, x2, y1, y2, bg, z, text } = info;
    if (blockWidth !== containerWidth) {
      const scale = blockWidth / containerWidth;
      x1 = x1 * scale;
      x2 = x2 * scale;
      y1 = y1 * scale;
      y2 = y2 * scale;
    }
    const style2 = {
      top: y1,
      left: x1,
      width: x2 - x1,
      height: y2 - y1,
      zIndex: z,
    };
    return (
      <div
        key={"click-" + index + "-" + clickIndex}
        className={classNames("ClickOverlay", bg)}
        style={style2}
        onClick={onClick}
        onMouseEnter={() => {
          setIsActive(true);
        }}
        onMouseLeave={() => setIsActive(false)}
      >
        {text && (
          <div
            className={classNames(
              "p-1",
              isActive ? "opacity-100" : "opacity-0",
              "transition-opacity  transition-slowest ease duration-300"
            )}
          >
            {text}
          </div>
        )}
      </div>
    );
  });

  return [overlay(), clickElements];
}
