import HoverRow from "../components/HoverRow";
import { Linking } from "react-native";

const HoverRowMail = ({ email, showText }) => {
  const mailSubject = "Contact";
  const mailBody = "Vertel%20ons%20wat%20je%20wilt%20weten!";

  return (
    <HoverRow
      imageBasic={"./images/contact/mail-small.png"}
      imageHover={"./images/contact/mail-hover-small.png"}
      text={showText ? email : ""}
      onClick={() =>
        window.open(`mailto:${email}?subject=${mailSubject}&body=${mailBody}`)
      }
    />
  );
};

const HoverRowPhone = ({ number, showText }) => {
  return (
    <HoverRow
      imageBasic={"./images/contact/mobile-small.png"}
      imageHover={"./images/contact/mobile-hover-small.png"}
      text={showText ? number : ""}
      onClick={() => Linking.openURL(`tel:${number}`)}
    />
  );
};

export { HoverRowMail, HoverRowPhone };
